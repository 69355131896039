import styled from 'styled-components';
import i18n from 'src/utils/translate';
import { textSmall } from 'styles-js/mixins/typography';
import { StyledSearchBar } from 'src/components/siteBar/sharedStyles';

export default function MobileSearchInput({ onCancel }: { onCancel: () => void }) {
  return (
    <Container>
      <StyledSearchBar onSearch={onCancel} />
      <CancelButton onClick={onCancel}>{i18n.t('Cancel')}</CancelButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin: 0 12px;
`;

const CancelButton = styled.button`
  ${textSmall};
  margin-left: 10px;
  border: 0;
  padding: 0;
  appearance: none;
  background-color: transparent;
  cursor: pointer;
`;
