// @ts-strict-ignore
import { useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import { Metadata } from 'src/constants/types';
import HeadSeo from 'src/utils/head';
import { fetchGuidelinesOnce } from 'src/modules/shared/context/actions';
import Alert from 'src/components/alert/alert';
import ModalRoot from 'src/components/modals/modalRoot';
import CommunityHeader from 'src/modules/community/shared/components/header/communityHeader';
import CommunityNavBar from 'src/modules/community/shared/components/header/communityHeaderNavBar';
import FloatingSignupBox from 'src/components/floatingSignupBox';
import SiteFooter from 'src/components/footer/siteFooter';
import Loader from 'src/components/loader';
import SiteBar from 'src/components/siteBar/siteBar';
import VerificationBar from 'src/components/verificationBar';
import BackToTop from 'src/components/buttons/backToTop';
import { ResultsContainer, ResultsContent, ResultsSidebar } from 'styles-js/resultsList';
import { H1 } from 'styles-js/mixins/screenReader';

type CommunityLayout = {
  children?: ReactNode;
  currentPage?: string;
  fullWidth?: boolean;
  seo?: Metadata;
  sideBarContent?: ReactNode;
};

export default function CommunityLayout({
  children,
  currentPage,
  fullWidth,
  seo,
  sideBarContent,
}: CommunityLayout): JSX.Element {
  const dispatch = useDispatch();

  const { slug = null, isPrivate = null } = useSelector(({ context }) => context.currentCommunity) || {};

  useEffect(() => {
    if (!slug) return;
    dispatch(fetchGuidelinesOnce(slug, isPrivate));
  }, [slug, isPrivate, dispatch]);

  return (
    <Container>
      {slug && <HeadSeo {...seo}/>}
      <SiteBar />
      <VerificationBar />
      <Alert />
      {!slug
        ? <Loader />
        : (
          <Main>
            <H1>{seo?.title || 'HealthUnlocked | The social network for health'}</H1>
            <CommunityHeader currentPage={currentPage} />
            <CommunityNavBar currentPage={currentPage} />
            <ResultsContainer>
              {fullWidth
                ? (
                  <FullWidthContainer data-testid="community-results-content">
                    {children}
                  </FullWidthContainer>
                ) : (
                  <>
                    <ResultsContent data-testid="community-results-content">
                      {children}
                    </ResultsContent>
                    <ResultsSidebar>
                      {sideBarContent}
                    </ResultsSidebar>
                  </>
                )
              }
            </ResultsContainer>
          </Main>
        )
      }
      <FloatingSignupBox />
      <BackToTop />
      <SiteFooter />
      <ModalRoot />
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  margin-top: ${({ theme }) => theme.navHeightTop};
  flex: 1;
`;

const FullWidthContainer = styled.div`
  @media (${({ theme }) => theme.underScreenXlarge}) and (${({ theme }) => theme.overScreenSmall}) {
    margin-right: 20px;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 0 15px;
  }
`;
