import i18n from 'src/utils/translate';

export default function Info() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 22 23"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Help')}</title>
      <path d="M11 21.329c5.468 0 9.9-4.433 9.9-9.9 0-5.468-4.432-9.9-9.9-9.9s-9.9 4.432-9.9 9.9c0 5.467 4.432 9.9 9.9 9.9ZM11 8.495l-.022 9.668M10.978 4.651 11 7.03" stroke="currentColor" strokeWidth="1.5"/>
    </svg>
  );
}
