import SocialButton from 'src/components/buttons/socialButton';

export default function Social() {
  return (
    <div itemScope={true} itemType="https://schema.org/Organization">
      <link href="https://healthunlocked.com" itemProp="url"/>
      <a
        aria-label="Facebook"
        data-testid="facebook-icon"
        href="https://facebook.com/healthunlocked"
        itemProp="sameAs"
        rel="noreferrer"
        target="_blank"
        title="Facebook"
      >
        <SocialButton brand="facebook" />
      </a>
      <a
        aria-label="Twitter"
        data-testid="twitter-icon"
        href="https://twitter.com/healthunlocked"
        itemProp="sameAs"
        rel="noreferrer"
        target="_blank"
        title="Twitter"
      >
        <SocialButton brand="twitter" />
      </a>
      <a
        aria-label="YouTube"
        data-testid="youtube-icon"
        href="https://youtube.com/healthunlocked"
        itemProp="sameAs"
        rel="noreferrer"
        target="_blank"
        title="YouTube"
      >
        <SocialButton brand="youtube" />
      </a>
      <a
        aria-label="Instagram"
        data-testid="instagram-icon"
        href="https://instagram.com/healthunlocked"
        itemProp="sameAs"
        rel="noreferrer"
        target="_blank"
        title="Instagram"
      >
        <SocialButton brand="instagram" />
      </a>
    </div>
  );
}
