import i18n from 'src/utils/translate';

export default function Paper() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 18 21"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Needs assessment')}</title>
      <path
        d="M5.4 5.629h7.2m-7.2 3.6h7.2m-7.2 3.6H9m-5.4-12h10.8a2.4 2.4 0 0 1 2.4 2.4v14.4a2.4 2.4 0 0 1-2.4 2.4H3.6a2.4 2.4 0 0 1-2.4-2.4v-14.4a2.4 2.4 0 0 1 2.4-2.4Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
