import styled from 'styled-components';

export const ResultsContainer = styled.div`
  display: flex;
  column-gap: 80px;
  min-height: 300px;
  max-width: 1200px;
  margin: 0 auto 60px;
  padding: 15px;
  @media (${({ theme }) => theme.underScreenXlarge}) and (${({ theme }) => theme.overScreenSmall}) {
    margin-left: 20px;
  }  
  @media (${({ theme }) => theme.underScreenSmall}) {
    flex-direction: column;
    padding: 0;
  }

  .no-results-summary {
    @media (${({ theme }) => theme.underScreenSmall}) {
      padding: 0 15px;
    }
  }
  .search-tips.no-query {
    @media (${({ theme }) => theme.underScreenXlarge}) and (${({ theme }) => theme.overScreenSmall}) {
      margin-left: 0;
    }
  }
`;

export const ResultsContent = styled.div`
  width: 65%;
  @media (${({ theme }) => theme.underScreenMedium}) {
    width: 67%;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    width: 100%;
  }
`;

export const ResultsSidebar = styled.div`
  width: 35%;
  @media (${({ theme }) => theme.underScreenMedium}) {
    width: 33%;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    width: 100%;
    padding: 0 15px;
  }
`;

export const ResultsSeeMoreCTA = styled.div `
  text-align: center;
  padding: 10px;

  button {
    width: 100%;
  }
`;

export const SeeMoreResults = styled.div`
  text-align: center;
  padding: 10px 0;
  width: 100%;
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 10px 20px;
  }

  button {
    width: 100%;
  }
`;
