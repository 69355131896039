import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { MediaQueries } from 'styles-js/theme';

export default function mediaQuery(query: string) {
  return window.matchMedia(`(${query})`).matches;
}

export function useMediaQuery(queryName: keyof MediaQueries) {
  const [isMatch, setIsMatch] = useState(false);

  const query = useContext(ThemeContext)?.[queryName];

  useEffect(() => {
    setIsMatch(mediaQuery(query));
  }, [query]);

  useEffect(() => {
    window.addEventListener('resize', () => setIsMatch(mediaQuery(query)));
    return () => window.removeEventListener('resize', () => setIsMatch(mediaQuery(query)));
  }, [query]);

  return isMatch;
}
