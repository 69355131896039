// @ts-strict-ignore
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { throttle } from 'lodash';
import { useDispatch, useSelector } from 'src/store/store';
import { showSignupModal } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event, Element, Section } from 'src/constants/footfall';
import i18n from 'src/utils/translate';
import { getLocalStorageItem, Key, setLocalStorageItem } from 'src/utils/localStorage';
import Animate from 'src/components/animate';
import { resetFloatingSignupBox } from 'src/modules/shared/context/actions';
import Button from 'src/components/buttons/button';

export default function FloatingBox() {
  const dispatch = useDispatch();

  const { currentUser, isFloatingSignupBoxVisible } = useSelector(({ context }) => context);

  const [showFloatingSignupBox, setShowFloatingSignupBox] = useState(false);
  const [email, setEmail] = useState('');

  const onScroll = throttle(() => {
    if (window.scrollY > 0) {
      setShowFloatingSignupBox(true);
    }
  }, 300);

  const hide = () => {
    setShowFloatingSignupBox(false);
    window.removeEventListener('scroll', onScroll);
    setLocalStorageItem(Key.hideFloatingBox, 'true');
  };

  const onSubmitEmail = (e) => {
    e.preventDefault();
    trackFootfallEvent(Event.CLICKED, {
      clickMetadata: { section: Section.StickyFooter, element: Element.Join },
      clickType: 'sign-up',
    });
    dispatch(showSignupModal({ defaultEmail: email }));
  };

  useEffect(() => {
    if (!isFloatingSignupBoxVisible) {
      dispatch(resetFloatingSignupBox());
    }
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  if (getLocalStorageItem(Key.hideFloatingBox) || currentUser || !isFloatingSignupBoxVisible) return null;

  return (
    <Animate show={showFloatingSignupBox} transitionType="TRANSITION-simpleFade">
      <div className="floating-box">
        <div className="floating-box-sign-up">
          <Button
            className="floating-box-sign-up__dismiss"
            icon="close"
            type="none"
            onClick={hide}
          />
          <div className="floating-box-sign-up__title">
            <span className="hidden-xs">
              {i18n.t('Connect with others who understand.')}
            </span>
            <span className="visible-xs">
              {i18n.t('Join the #1 peer-support network for health. Ask questions. Get answers.')}
            </span>
          </div>
          <div className="floating-box-sign-up__content">
            <span className="hidden-xs">
              {i18n.t('Join the #1 peer-support network for health. Ask questions. Get answers.')}
            </span>
            <form className="floating-box-sign-up__content__form" onSubmit={e => onSubmitEmail(e)}>
              <input
                className="form-control floating-box-sign-up__form-input"
                placeholder={i18n.t('email@address.com')}
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <StyledButton isSubmit={true} size="lg">
                <span className="hidden-xs">{i18n.t('Join HealthUnlocked')}</span>
                <span className="visible-xs">{i18n.t('Join')}</span>
              </StyledButton>
            </form>
          </div>
        </div>
      </div>
    </Animate>
  );
}

const StyledButton = styled(Button)`
  @media (${props => props.theme.underScreenSmall}) {
    margin: -2px 0px 0px 0px;
    padding: 8px 20px 9px 20px;
  }
`;
