import i18n from 'src/utils/translate';

export default function Cog() {
  return (
    <svg
      fill="none"
      height="23"
      viewBox="0 0 26 26"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Account settings')}</title>
      <path
        d="M9.578 4.15 11.074 2a1 1 0 0 1 .82-.429h2.212a1 1 0 0 1 .82.426l1.48 2.112m-6.828.04c-.396.566-1.055.843-1.754.793m1.754-.793c-.398.564-1.055.845-1.754.793m0 0L5.18 4.776a1 1 0 0 0-.835.364l-1.4 1.706a1 1 0 0 0-.178.943l.774 2.383m0 0c.101.329.114.682.042 1.015m-.042-1.015c.103.328.115.68.041 1.019m0-.004c-.073.33-.231.641-.466.893m.467-.893v.004m-.467.889-1.75 1.883a1 1 0 0 0-.24.915l.496 2.056a1 1 0 0 0 .628.704l2.405.882c.326.126.607.33.82.588M3.116 12.08c.236-.25.393-.56.466-.89m1.893 7.918c.212.26.356.571.411.91m-.41-.91c.212.26.357.57.41.91m-.41-.91-.004-.003a2.166 2.166 0 0 0-.044-.051m.458.963.41 2.516a1 1 0 0 0 .566.746l1.995.927a1 1 0 0 0 .91-.035l2.25-1.259m0 0c.303-.17.642-.255.98-.256m-.98.256a1.94 1.94 0 0 1 .98-.256m0 0a2.041 2.041 0 0 1 .984.256m-.984-.256c.34 0 .68.085.984.256m0 0 2.354 1.274a1 1 0 0 0 .907.023l1.985-.948a1 1 0 0 0 .558-.754l.368-2.447m0 0c.053-.34.195-.654.406-.915m-.406.916c.051-.341.194-.655.406-.916m0 0c.21-.258.488-.464.811-.591m-.81.59c.208-.258.485-.465.81-.59m0 0 2.428-.952a1 1 0 0 0 .61-.708l.475-2.077a1 1 0 0 0-.24-.901l-1.76-1.906m0 0a1.985 1.985 0 0 1-.487-.876m.487.876a2.007 2.007 0 0 1-.487-.876m0 0a1.825 1.825 0 0 1 .026-.983l.762-2.442a1 1 0 0 0-.182-.933l-1.367-1.663a1 1 0 0 0-.846-.362l-2.615.191m0 0a2.011 2.011 0 0 1-.994-.184m.994.184c-.348.026-.69-.04-.994-.184m0 0a1.984 1.984 0 0 1-.775-.65m.775.65a1.96 1.96 0 0 1-.775-.65M3.582 11.192l.001-.005m13.287 1.826c0 2.131-1.767 3.86-3.946 3.86-2.18 0-3.946-1.729-3.946-3.86s1.767-3.859 3.946-3.859c2.179 0 3.946 1.728 3.946 3.86Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
