// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import { setAlert } from 'src/components/alert/actions';
import actionTypes from 'src/modules/user/profile/actionTypes';
import contextActionTypes from 'src/modules/shared/context/actionTypes';
import api, { createAuthenticatedApiInstance } from 'src/utils/api';
import i18n from 'src/utils/translate';
import { PostType } from 'src/constants/types';

export const fetchProfileForUsername = (username) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_USER_PROFILE });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const { data } = await api.get(`private/user/profile/${username}`);
      return dispatch({
        type: actionTypes.FETCH_USER_PROFILE_SUCCESS,
        data,
      });
    }
    catch (ex) {
      captureException(ex);
      dispatch({
        type: actionTypes.FETCH_USER_PROFILE_FAILURE,
        error: ex,
      });
      dispatch(setAlert(i18n.t('User does not exist.')));
    }
  };
};

export const fetchUserPosts = (userId, postTypeId, createdBeforePostId = null) => {
  return async (dispatch, getState) => {
    dispatch({ type: postTypeId === PostType.Reply ? actionTypes.FETCH_USER_REPLIES : actionTypes.FETCH_USER_POSTS });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const { data } = await api.get(`private/posts/${userId}`, { params: { postTypeId, createdBeforePostId } });

      dispatch({
        type: postTypeId === PostType.Reply ? actionTypes.FETCH_USER_REPLIES_SUCCESS : actionTypes.FETCH_USER_POSTS_SUCCESS,
        data,
        createdBeforePostId,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: postTypeId === PostType.Reply ? actionTypes.FETCH_USER_REPLIES_FAILURE : actionTypes.FETCH_USER_POSTS_FAILURE });
    }
  };
};

export const followUser = (userToFollowId, successMessage) => {
  return async (dispatch, getState) => {
    const state = getState();
    const profileUser = state.pages.profile.profileUser;
    const isProfileUserFollowed = userToFollowId === profileUser.userId;
    dispatch({ type: actionTypes.FOLLOW_USER });
    try {
      const { data } = await api.post(`private/users/${userToFollowId}/follow`);
      if (data.success === false) {
        dispatch(setAlert());
      } else {
        const totalFollowers = profileUser.totalFollowers || 0;
        const totalFollowing = profileUser.totalFollowing || 0;
        dispatch({
          type: actionTypes.FOLLOW_USER_SUCCESS,
          data: {
            followers: isProfileUserFollowed ? (totalFollowers + 1) : totalFollowers,
            following: profileUser.isOwnProfile ? (totalFollowing + 1) : totalFollowing,
            isFollowedByCurrentUser: isProfileUserFollowed ? true : profileUser.isFollowedByCurrentUser,
          },
        });
        dispatch(setAlert(successMessage, 'success'));
        dispatch({
          type: contextActionTypes.GET_FOLLOWED_USERS,
          followedUserIds: data.followedUserIds,
        });
      }
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert(ex.message));
      dispatch({ type: actionTypes.FOLLOW_USER_FAILURE, error: ex });
    }
  };
};

export const unfollowUser = (userToUnfollowId, successMessage) => {
  return async (dispatch, getState) => {
    const state = getState();
    const profileUser = state.pages.profile.profileUser;
    const isProfileUserUnfollowed = userToUnfollowId === profileUser.userId;
    dispatch({ type: actionTypes.UNFOLLOW_USER });
    try {
      const { data } = await api.del(`private/users/${userToUnfollowId}/follow`);

      if (data.success === false) {
        dispatch(setAlert());
      } else {
        const totalFollowers = profileUser.totalFollowers || 1;
        const totalFollowing = profileUser.totalFollowing || 1;

        dispatch({
          type: actionTypes.UNFOLLOW_USER_SUCCESS,
          data: {
            followers: isProfileUserUnfollowed ? (totalFollowers - 1) : profileUser.totalFollowers || 0,
            following: profileUser.isOwnProfile ? (totalFollowing - 1) : profileUser.totalFollowing || 0,
            isFollowedByCurrentUser: isProfileUserUnfollowed ? false : profileUser.isFollowedByCurrentUser,
          },
        });
        dispatch(setAlert(successMessage, 'success'));
        dispatch({
          type: contextActionTypes.GET_FOLLOWED_USERS,
          followedUserIds: data.followedUserIds,
        });
      }
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UNFOLLOW_USER_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const fetchConnections = (userId, filter, pageNumber = 1) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_CONNECTIONS });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const { data } = await api.get(`private/users/${userId}/${filter}`, { params: { pageNumber } });
      dispatch({
        type: actionTypes.FETCH_CONNECTIONS_SUCCESS,
        data: { data, filter, pageNumber },
      });
    }
    catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_CONNECTIONS_FAILURE, error: ex });
      dispatch(setAlert(ex.message));
    }
  };
};

export const setCurrentTab = (currentTab) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_TAB,
      currentTab: currentTab,
    });
  };
};

export const muteUser = (currentUserId, mutedUserId) => {
  return api.post(`private/users/${currentUserId}/mute-user`, { mutedUserId });
};

export const unmuteUser = (currentUserId, mutedUserId) => {
  return async (dispatch) => {
    try {
      await api.post(`private/users/${currentUserId}/unmute-user`, { mutedUserId });
      dispatch({ type: actionTypes.UNMUTE_USER_SUCCESS });
    }
    catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UNMUTE_USER_FAILURE, error: ex });
      dispatch(setAlert());
    }
  };
};
