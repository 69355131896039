// @ts-strict-ignore
import { useId, useState } from 'react';
import classNames from 'classnames';
import Autosuggest from 'react-autosuggest';
import { throttle } from 'lodash';
import { performSearch } from 'src/utils/router';
import api from 'src/utils/api';
import i18n from 'src/utils/translate';
import SearchBarInput, { useFocus } from 'src/modules/search/shared/components/searchBarInput';
import SearchBarSuggestion from 'src/modules/search/shared/components/searchBarSuggestion';

export type Props = {
  className?: string;
  onClick?: () => void;
  onSearch?: () => void;
}
export default function SearchBar({ className, onClick, onSearch }: Props) {
  const { isFocus, setIsFocus, inputRef } = useFocus();
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const id = useId();

  const doSearch = (value) => {
    setIsFocus(false);
    performSearch(value);
    setQuery('');
    if (onSearch) onSearch();
  };

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    doSearch(query);
  };

  const onSuggestionsFetchRequested = throttle(async ({ value = '' }) => {
    if (value.length <= 1) return;

    const { data } = await api.getPublic(`search/autocomplete?q=${value}`);
    setSuggestions(data.slice(0, 10).map(x => x.toLowerCase()));
  }, 500);

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const onSuggestionSelected = (_, { suggestion }) => doSearch(suggestion);

  const containerClassName = classNames({
    'search-bar': true,
    [className]: !!className,
  });
  const inputProps = {
    className: 'search-bar__input',
    placeholder: i18n.t('Search HealthUnlocked'),
    onChange: (_, { newValue }) => setQuery(newValue),
    value: query,
    type: 'text',
    onBlur: () => setIsFocus(false),
    onClick,
    onFocus: () => setIsFocus(true),
  };

  return (
    <div className={containerClassName}>
      <form
        className="search-bar__form"
        data-testid="search-bar"
        onSubmit={handleSubmit}
      >
        <Autosuggest
          getSuggestionValue={suggestion => suggestion}
          id={id}
          inputProps={inputProps}
          renderInputComponent={(renderInputComponentProps) => (
            <SearchBarInput
              inputRef={inputRef}
              isFocus={isFocus}
              query={query}
              renderInputComponentProps={renderInputComponentProps}
            />
          )}
          renderSuggestion={(text) => <SearchBarSuggestion query={query} text={text} />}
          suggestions={suggestions}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        />
      </form>
    </div>
  );
}
