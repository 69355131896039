import i18n from 'src/utils/translate';

export default function Pen() {
  return (
    <svg
      fill="none"
      height="19"
      viewBox="0 0 20 19"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Post')}</title>
      <path
        d="m3.056 12.32 3.85 3m2.75 2.5h8.8m-15.95-5.5L13.35 2.117c1.168-1.062 3.061-1.062 4.229 0 1.168 1.061 1.168 2.782 0 3.844l-11.224 9.86-5.5 1.5 1.65-5Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
