import i18n from 'src/utils/translate';

export default function Logout() {
  return (
    <svg
      fill="none"
      height="18"
      viewBox="0 0 20 19"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Logout')}</title>
      <path
        d="M12.647 5.229v-2.1c0-.557-.223-1.092-.62-1.485a2.127 2.127 0 0 0-1.498-.615H3.118c-.562 0-1.1.22-1.498.615-.397.393-.62.928-.62 1.485v12.6c0 .556.223 1.09.62 1.485.397.393.936.615 1.498.615h7.411c.562 0 1.1-.222 1.498-.616a2.09 2.09 0 0 0 .62-1.484v-2.1m-6.353-4.2H19m0 0-3.177-3.15M19 9.429l-3.177 3.15"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
