// @ts-strict-ignore
import styled, { css } from 'styled-components';
import { getCommunityLogoUrl } from 'src/utils/linkUtils';
import ImageUpload from 'src/components/imageUpload';
import { saveAndCropLogoImage } from 'src/api/community';
import { useSelector } from 'src/store/store';
import { Role } from 'src/constants/types';
import { userHasRole } from 'src/utils/context';
import i18n from 'src/utils/translate';

export default function Logo() {
  const { logoImageHash, slug } = useSelector(({ context }) => context.currentCommunity);
  const currentUser = useSelector(({ context }) => context.currentUser);

  const imageUrl = getCommunityLogoUrl(logoImageHash);
  const isHUStaff = userHasRole(currentUser, [Role.HealthUnlocked]);

  return (
    <Wrapper>
      {isHUStaff ? (
        <ImageUpload
          buttonText={i18n.t('Add logo')}
          currentImageUrl={imageUrl}
          customCss={communityLogo}
          saveRequest={(formData) => saveAndCropLogoImage(slug, formData)}
          type="avatar"
        />
      ) : <CommunityLogo $imageUrl={imageUrl} />}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 15px;
  left: 10px;
  border-radius: 50%;
  box-shadow: ${({ theme }) => theme.shadowLight};
  border: ${({ theme }) => theme.greyLine};
  background-color: ${({ theme }) => theme.colorWhite};

  @media (${({ theme }) => theme.underScreenSmall}) {
    top: -38px;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;

const communityLogo = css`
  border-radius: 50%;
  width: 130px;
  height: 130px;
  border: 5px solid ${({ theme }) => theme.colorWhite};
  background-color: ${({ theme }) => theme.colorGreyLight};
  z-index: 100;

  @media (${({ theme }) => theme.underScreenSmall}) {
    border: 2px solid ${({ theme }) => theme.colorWhite};
    width: 56px;
    height: 56px;
  }
`;

const CommunityLogo = styled.div<{ $imageUrl: string }>`
  ${communityLogo}
  background-image: url(${({ $imageUrl }) => $imageUrl});
  background-size: cover;
`;
