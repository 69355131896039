import { SyntheticEvent } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import SitebarDropdown, { Divider, Dropdown, SitebarDropdownLink } from 'src/components/siteBar/sitebarDropdown';
import NotificationList from 'src/components/siteBar/notificationList';
import Loader from 'src/components/loader';
import { text } from 'styles-js/mixins/typography';
import Bell from 'public/static/img/icons/bell';
import { Counter, DropdownButton, ImageContainer, LinkText } from 'src/components/siteBar/sharedStyles';

const NOTIFICATION_MENU_LIMIT = 5;

type Props = {
  isOpen: boolean;
  handleClick: (e: SyntheticEvent) => void;
  setOpenNotifications: (isOpen: boolean) => void;
}
export default function NotificationMenu({ isOpen, handleClick, setOpenNotifications }: Props): JSX.Element {
  const { asPath } = useRouter();
  const route = asPath.split('/')[1];
  const { isLoading, items, unreadNotificationsCount } = useSelector(({ context }) => context.notifications) ?? {};

  return (
    <Container>
      <DropdownButton
        $isActive={isOpen || route === 'notifications'}
        data-testid="sitebar-alerts"
        onClick={handleClick}
      >
        <ImageContainer>
          <Bell />
          {unreadNotificationsCount > 0 && <Counter>{unreadNotificationsCount}</Counter>}
        </ImageContainer>
        <LinkText $isActive={isOpen || route === 'notifications'}>{i18n.t('Alerts')}</LinkText>
      </DropdownButton>
      <SitebarDropdown dataTestId="sitebar-notifications-menu" isOpen={isOpen}>
        <Divider>{i18n.t('Notifications')}</Divider>
        <NotificationList limit={NOTIFICATION_MENU_LIMIT} onClick={() => setOpenNotifications(false)} />
        {items?.length > NOTIFICATION_MENU_LIMIT && (
          <Link
            legacyBehavior
            passHref
            as="/notifications"
            href="/notifications"
          >
            <SitebarDropdownLink as="a">
              {`+ ${i18n.t('view all notifications')}`}
            </SitebarDropdownLink>
          </Link>
        )}
        {items?.length === 0 && (
          isLoading
            ? <Loader />
            : <Empty>{i18n.t(`This is where we'll notify you when others follow you or interact with your posts and replies.`)}</Empty>
        )}
      </SitebarDropdown>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  ${Dropdown} {
    right: 0;
  }
  ul li a {
    white-space: normal;
  }
`;

const Empty = styled.p`
  ${text}
  margin: 10px 15px;
`;
