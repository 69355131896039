import { SyntheticEvent } from 'react';
import Script from 'next/script';
import Link from 'next/link';
import styled from 'styled-components';
import { useDispatch } from 'src/store/store';
import { logout as logoutAction } from 'src/modules/shared/auth/actions';
import i18n from 'src/utils/translate';
import { Category, useCookieCategory } from 'src/utils/cookie';
import SitebarDropdown, { Dropdown } from 'src/components/siteBar/sitebarDropdown';
import { titleSmall } from 'styles-js/mixins/typography';
import Menu from 'public/static/img/icons/menu';
import Info from 'public/static/img/icons/info';
import Cog from 'public/static/img/icons/cog';
import Mail from 'public/static/img/icons/mail';
import Paper from 'public/static/img/icons/paper';
import Logout from 'public/static/img/icons/logout';
import { DropdownButton, ImageContainer, LinkText } from 'src/components/siteBar/sharedStyles';

type Props = {
  isOpen: boolean;
  handleClick: (e: SyntheticEvent) => void;
  setOpenMoreMenu: (isOpen: boolean) => void;
};

export default function MoreMenu({ isOpen, handleClick, setOpenMoreMenu }: Props) {
  const dispatch = useDispatch();
  const [isFunctionalityAccepted] = useCookieCategory(Category.Functionality);

  return (
    <Container>
      {isFunctionalityAccepted && // TECH-279
        <Script src="https://connect.facebook.net/en_US/sdk.js" />
      }
      <DropdownButton
        $isActive={isOpen}
        data-testid="sitebar-user-menu"
        onClick={handleClick}
      >
        <ImageContainer>
          <Menu />
        </ImageContainer>
        <LinkText $isActive={isOpen}>{i18n.t('Menu')}</LinkText>
      </DropdownButton>

      <SitebarDropdown dataTestId="desktop-more-menu" isOpen={isOpen}>
        <Link
          legacyBehavior
          passHref
          as={'/settings'}
          href={'/user/settings'}
        >
          <MenuLink data-testid={'sitebar-settings'} onClick={() => setOpenMoreMenu(false)}>
            <div><Cog /></div>
            <span>{i18n.t('Account settings')}</span>
          </MenuLink>
        </Link>
        <Link
          legacyBehavior
          passHref
          as={'/email-notifications'}
          href={'/emailNotifications'}
        >
          <MenuLink data-testid={'sitebar-email-notifications'} onClick={() => setOpenMoreMenu(false)}>
            <div><Mail /></div>
            <span>{i18n.t('Email preferences')}</span>
          </MenuLink>
        </Link>
        <Link
          legacyBehavior
          passHref
          as={'/your-needs'}
          href={'/needsAssessment/needsAssessmentList'}
        >
          <MenuLink data-testid={'sitebar-your-needs'} onClick={() => setOpenMoreMenu(false)}>
            <div><Paper /></div>
            <span>{i18n.t('Needs assessment')}</span>
          </MenuLink>
        </Link>
        <MenuLink
          href="#"
          onClick={(e) => {
            e.preventDefault();
            window.HelpScoutBeacon.triggerHelpScoutBeacon('USER_SUPPORT');
          }}
        >
          <div><Info /></div>
          <span>{i18n.t('Get help using this site')}</span>
        </MenuLink>
        <MenuLink
          data-testid={'sitebar-log-out'}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            dispatch(logoutAction());
          }}
        >
          <div><Logout /></div>
          <span>{i18n.t('Log out')}</span>
        </MenuLink>
      </SitebarDropdown>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  ${Dropdown} {
    right: 0;
  }
`;

const MenuLink = styled.a`
  && { // precedence boost needed
    ${titleSmall}
    display: flex;
    align-items: center;
    padding: 10px 20px;
    img {
      margin-right: 0;
    }
  }

  &:focus, &:hover, &:active  {
    color: ${({ theme }) => theme.colorBlack};
    background-color: ${({ theme }) => theme.colorGreenLight};
  }

  div {
    flex-basis: 24px;
    display: flex;
    justify-content: center;
    color: ${({ theme }) => theme.colorGreyDark};
  }
  span {
    margin-left: 15px;
  }
`;
