import styled from 'styled-components';
import CommunityBanner from 'src/modules/community/shared/components/header/communityBanner';
import Logo from 'src/modules/community/shared/components/header/logo';
import CommunityActions from 'src/modules/community/shared/components/header/communityActions';
import Details from 'src/modules/community/shared/components/header/details';

export default function CommunityHeader({ currentPage = '' }: { currentPage?: string }): JSX.Element {

  return (
    <Wrapper className="js-community-header">
      <CommunityBanner />
      <Content data-testid="community-header-desc">
        <Logo />
        <Details />
        <CommunityActions currentPage={currentPage} />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colorWhite};
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.colorGreyLight};
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 15px 15px 0;
  max-width: 1200px;
  margin: 0 auto;
  
  @media (${({ theme }) => theme.underScreenSmall}) {
    flex-direction: column;
    padding: 15px;
  }
  
  @media ($under-screen-xlarge) and (${({ theme }) => theme.overScreenSmall}) {
    margin-left: 20px;
  }
`;
