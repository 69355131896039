import styled from 'styled-components';
import VisitorSiteBar from 'src/components/siteBar/visitorSiteBar';
import DesktopSiteBar from 'src/components/siteBar/desktopSiteBar';
import MobileSiteBarTop from 'src/components/siteBar/mobileSiteBarTop';
import MobileSiteBarBottom from 'src/components/siteBar/mobileSiteBarBottom';
import { useIsLoggedIn } from 'src/utils/hooks/useIsLoggedIn';
import { useMediaQuery } from 'src/utils/mediaQuery';

export default function SiteBar(): JSX.Element {
  const isLoggedIn = useIsLoggedIn();
  const isDesktop = useMediaQuery('overScreenSmall');

  if (!isLoggedIn) return <VisitorSiteBar />;

  return (
    <MemberSiteBar aria-label="main navigation">
      {isDesktop
        ? <DesktopSiteBar />
        : (
          <>
            <MobileSiteBarTop />
            <MobileSiteBarBottom />
          </>
        )}
    </MemberSiteBar>
  );
}

const MemberSiteBar = styled.nav`
  @media print {
    display: none;
  }
`;
