// @ts-strict-ignore
import Link from 'next/link';
import { truncate } from 'underscore.string';
import styled from 'styled-components';
import Button from 'src/components/buttons/button';
import i18n from 'src/utils/translate';
import { showModal, closeModal } from 'src/modules/shared/modal/actions';
import { resendVerificationLink } from 'src/modules/shared/context/actions';
import { useDispatch, useSelector } from 'src/store/store';
import { ModalType } from 'src/constants/modalTypes';
import { textSmall, titleSmall } from 'styles-js/mixins/typography';

export default function VerificationBar(): JSX.Element {
  const dispatch = useDispatch();

  const { currentUser, showVerificationBar } = useSelector(({ context }) => context);

  if (!currentUser || !showVerificationBar) return null;

  const unverifiedEmail = truncate(currentUser?.unverifiedEmail || '', 40);

  const onResendEmailClick = () => {
    dispatch(resendVerificationLink());
    dispatch(closeModal());
  };

  const onInformationClick = () => {
    dispatch(showModal({
      modalType: ModalType.EmailConfirmation,
      modalProps: {
        unverifiedEmail,
        onResendEmailClick,
      },
    }));
  };

  return (
    <Bar className="js-verification-bar">
      <Inner>
        <Text>
          {i18n.t('Confirm your email by clicking the link we sent to %1$s').replace('%1$s', unverifiedEmail)}
          <Button icon="information" type="none" onClick={onInformationClick} />
        </Text>
        <Buttons>
          <Button
            text="Resend email"
            onClick={onResendEmailClick}
          />
          <Link
            as="/settings#email-update_settings"
            href="/user/settings#email-update_settings"
          >
            {i18n.t('Change email')}
          </Link>
        </Buttons>
      </Inner>
    </Bar>
  );
}

const Bar = styled.div`
  ${titleSmall}
  position: relative;
  top: ${({ theme }) => theme.navHeightTop};
  z-index: 1000;
  background-color: ${({ theme }) => theme.colorMagenta};
  color: ${({ theme }) => theme.colorWhite};
`;
const Inner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  min-height: ${({ theme }) => theme.navHeightTop};
  margin: 0 auto;
  padding: 5px 15px;
  gap: 15px;
  @media (${({ theme }) => theme.underScreenSmall}) {
    flex-direction: column;
    padding: 15px;
    gap: 5px;
  }
`;
const Text = styled.div`
  button {
    padding: 0 4px;
  }
  .icon {
    font-size: 25px;
    color: ${({ theme }) => theme.colorWhite};
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  min-width: 240px;
  button {
    margin: 0;
    background-color: ${({ theme }) => theme.colorMagenta};
    border: 1px solid ${({ theme }) => theme.colorWhite};
  }
  a {
    ${textSmall};
    line-height: 30px;
    color: ${({ theme }) => theme.colorWhite};
    &:hover {
      color: ${({ theme }) => theme.colorWhite};
    }
  }
`;
