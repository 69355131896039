import i18n from 'src/utils/translate';

export default function PaperPlane() {
  return (
    <svg
      fill="none"
      height="21"
      viewBox="0 0 22 21"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Chat')}</title>
      <path
        d="m21.002 1.322-4.195 17.781-7.254-4.21 11.449-13.57ZM7.047 13.43c.49-.341 13.955-12.108 13.955-12.108L1.247 11.084l5.8 2.346Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
      <path
        d="m8.026 19.709 3.79-3.501-2.262-1.315-1.528 4.816Zm0 0-.98-6.278"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
}
