import styled from 'styled-components';
import { useSelector } from 'src/store/store';
import NotificationItem from 'src/components/siteBar/notificationItem';

type Props = {
  limit?: number;
  className?: string;
  onClick?: () => void;
}
export default function NotificationList({ className, limit = 50, onClick }: Props): JSX.Element {
  const { items = [] } = useSelector(({ context }) => context.notifications);

  return (
    <List className={className} onClick={onClick}>
      {items.slice(0, limit).map((notification) => <li key={notification.id}><NotificationItem notification={notification} /></li>)}
    </List>
  );
}


const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;
