export default function SearchBarSuggestion({ text, query }: { text: string; query: string }) {
  const highlight = query;
  // queries with special characters (e.g. 'Pregnancy (difficulties)') were breaking the RegExp so they are now escaped
  const escapedHighlight = highlight.replace(/[-[\]{}()*+?.,\\^$|#]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
  return (
    <>
      {parts.filter(Boolean).map((part) => (
        <span
          key={part}
          style={part.toLowerCase() === highlight.toLowerCase() ? { fontWeight: 'bold' } : {}}
        >
          {part}
        </span>
      ))}
    </>
  );
}
