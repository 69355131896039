import i18n from 'src/utils/translate';

export default function Bell() {
  return (
    <svg
      fill="none"
      height="21"
      viewBox="0 0 18 21"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Alerts')}</title>
      <path
        d="M16.557 14.916a2.032 2.032 0 0 1-.378-.52 37.725 37.725 0 0 1-1.091-7.818c0-2.043-1.88-3.75-4.42-4.263-.151-.57-.687-1.483-1.653-1.483-.965 0-1.473.924-1.653 1.503-2.506.53-4.332 2.25-4.332 4.243a37.726 37.726 0 0 1-1.091 7.818c-.097.19-.225.365-.378.52a2.411 2.411 0 0 0-.832 1.62c-.01.284.07.563.23.806.162.242.397.437.677.56a21.2 21.2 0 0 0 5.143.825c.196.471.842 1.748 2.247 1.748 1.404 0 2.053-1.267 2.247-1.758a21.731 21.731 0 0 0 5.143-.815c.282-.122.518-.316.68-.559.16-.242.24-.523.227-.806a2.265 2.265 0 0 0-.766-1.62ZM9.027 1.791c.173 0 .346.157.464.357-.14-.01-.294-.02-.42-.02-.163 0-.336.01-.498.02.119-.196.292-.373.454-.353V1.79ZM9.07 19.56a1.475 1.475 0 0 1-.677-.25 1.292 1.292 0 0 1-.446-.526c.443.02.82.02 1.123.02.303 0 .67-.01 1.102-.02-.089.208-.239.39-.433.528a1.436 1.436 0 0 1-.669.25v-.002Zm6.99-2.485a29.932 29.932 0 0 1-13.958 0c-.205-.078-.295-.383-.295-.49.058-.402.259-.775.573-1.061.263-.259.458-.568.572-.904a36.941 36.941 0 0 0 1.124-8.054C4.086 4.64 6.323 3.09 9.078 3.09s4.948 1.551 4.991 3.516a37.02 37.02 0 0 0 1.124 8.053c.11.338.306.648.572.904.317.27.52.63.573 1.021a.569.569 0 0 1-.074.277.633.633 0 0 1-.204.217v-.003Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth=".4"
      />
    </svg>
  );
}
