import i18n from 'src/utils/translate';

export default function Mail() {
  return (
    <svg
      fill="none"
      height="16"
      viewBox="0 0 24 19"
      width="21"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Email preferences')}</title>
      <path
        d="m3.063 3.491 8.154 5.646c.471.326 1.095.326 1.566 0l8.155-5.646M3.75 17.929h16.5a2.75 2.75 0 0 0 2.75-2.75v-11a2.75 2.75 0 0 0-2.75-2.75H3.75A2.75 2.75 0 0 0 1 4.179v11a2.75 2.75 0 0 0 2.75 2.75Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
      />
    </svg>
  );
}
