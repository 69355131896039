import i18n from 'src/utils/translate';

export default function Hub() {
  return (
    <svg
      fill="none"
      height="21"
      viewBox="0 0 22 21"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('My Hub')}</title>
      <path
        d="M5.25 3.381c0 1.026-.938 1.977-2.25 1.977S.75 4.408.75 3.381c0-1.026.938-1.977 2.25-1.977s2.25.95 2.25 1.977ZM5.25 17.927c0 1.026-.938 1.977-2.25 1.977S.75 18.953.75 17.927c0-1.027.938-1.978 2.25-1.978s2.25.951 2.25 1.978ZM13.25 3.381c0 1.026-.938 1.977-2.25 1.977s-2.25-.95-2.25-1.977c0-1.026.938-1.977 2.25-1.977s2.25.95 2.25 1.977ZM13.25 17.927c0 1.026-.938 1.977-2.25 1.977s-2.25-.951-2.25-1.977c0-1.027.938-1.978 2.25-1.978s2.25.951 2.25 1.978ZM5.25 10.654c0 1.026-.938 1.977-2.25 1.977s-2.25-.95-2.25-1.977c0-1.027.938-1.977 2.25-1.977s2.25.95 2.25 1.977ZM13.25 10.654c0 1.026-.938 1.977-2.25 1.977s-2.25-.95-2.25-1.977c0-1.027.938-1.977 2.25-1.977s2.25.95 2.25 1.977ZM21.25 3.381c0 1.026-.939 1.977-2.25 1.977s-2.25-.95-2.25-1.977c0-1.026.939-1.977 2.25-1.977s2.25.95 2.25 1.977ZM21.25 17.927c0 1.026-.939 1.977-2.25 1.977s-2.25-.951-2.25-1.977c0-1.027.939-1.978 2.25-1.978s2.25.951 2.25 1.978ZM21.25 10.654c0 1.026-.939 1.977-2.25 1.977s-2.25-.95-2.25-1.977c0-1.027.939-1.977 2.25-1.977s2.25.95 2.25 1.977Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </svg>
  );
}
