// @ts-strict-ignore
import dynamic from 'next/dynamic';
import { useSelector } from 'src/store/store';
import { ModalType } from 'src/constants/modalTypes';

const CreatePollConfirmation = dynamic(() => import('src/modules/community/writePoll/createPollConfirmationModal'), { ssr: false });
const ChooseTopic = dynamic(() => import('src/modules/shared/modal/components/chooseTopicModal'), { ssr: false });
const CommunityGuidelines = dynamic(() => import('src/modules/community/shared/components/communityGuidelinesModal'), { ssr: false });
const CommunityInvites = dynamic(() => import('src/modules/shared/modal/components/communityInvites/communityInvitesModal'), { ssr: false });
const CommunityRemoveMember = dynamic(() => import('src/modules/shared/modal/components/communityRemoveMemberModal'), { ssr: false });
const CommunityTopConditions = dynamic(() => import('src/modules/profileSurvey/components/communityTopConditionsModal'), { ssr: false });
const Confirm = dynamic(() => import('src/modules/shared/modal/components/confirmModal'), { ssr: false });
const ContactAdmins = dynamic(() => import('src/modules/shared/modal/components/contactAdminsModal'), { ssr: false });
const ContactDetails = dynamic(() => import('src/modules/community/communityAbout/components/contactDetailsModal'), { ssr: false });
const Default = dynamic(() => import('src/modules/shared/modal/components/defaultModal'), { ssr: false });
const DeletePoll = dynamic(() => import('src/modules/community/singlePost/components/deletePollModal'), { ssr: false });
const DeletePost = dynamic(() => import('src/modules/community/singlePost/components/deletePostModal'), { ssr: false });
const EditBadges = dynamic(() => import('src/modules/shared/modal/components/editBadges/editBadgesModal'), { ssr: false });
const EmailConfirmation = dynamic(() => import('src/modules/shared/modal/components/emailConfirmationModal'), { ssr: false });
const EmailSubscribeAllPosts = dynamic(() => import('src/modules/unsubscribe/subscribeAllPostsModal'), { ssr: false });
const EmailUnsubscribeAllEmails = dynamic(() => import('src/modules/unsubscribe/unsubscribeAllEmailsModal'), { ssr: false });
const ExistingLoginSocial = dynamic(() => import('src/modules/shared/modal/components/signup/existingLoginSocialModal'), { ssr: false });
const Feedback = dynamic(() => import('src/modules/shared/feedback/feedbackModal'), { ssr: false });
const GoogleAds = dynamic(() => import('src/modules/shared/modal/components/googleAdsModal'), { ssr: false });
const GuidelinesAdd = dynamic(() => import('src/modules/community/communityAbout/components/guidelinesAddModal'), { ssr: false });
const GuidelinesDelete = dynamic(() => import('src/modules/community/communityAbout/components/guidelinesDeleteModal'), { ssr: false });
const Iframe = dynamic(() => import('src/modules/shared/modal/components/iframeModal'), { ssr: false });
const ImageDescription = dynamic(() => import('src/components/textEditor/components/imageDescriptionModal'), { ssr: false });
const Login = dynamic(() => import('src/modules/shared/modal/components/login/loginModal'), { ssr: false });
const MarketingOptIn = dynamic(() => import('src/modules/shared/modal/components/marketingOptInModal'), { ssr: false });
const MessageArchive = dynamic(() => import('src/modules/messages/messageArchiveModal'), { ssr: false });
const MessageEditSubject = dynamic(() => import('src/modules/messages/thread/messageEditSubjectModal'), { ssr: false });
const MessageLeave = dynamic(() => import('src/modules/messages/messageLeaveModal'), { ssr: false });
const MessageMute = dynamic(() => import('src/modules/messages/messageMuteModal'), { ssr: false });
const MessageNew = dynamic(() => import('src/modules/messages/messageNewModal'), { ssr: false });
const MessageParticipants = dynamic(() => import('src/modules/messages/thread/messageParticipantsModal'), { ssr: false });
const NeedsAssessment = dynamic(() => import('src/modules/needsAssessment/needsAssessmentList/components/needsAssessmentModal'), { ssr: false });
const NHSTagContent = dynamic(() => import('src/modules/tag/NHSContentModal'), { ssr: false });
const Onboarding = dynamic(() => import('src/modules/shared/modal/components/signup/onboardingModal'), { ssr: false });
const PrivacyReminder = dynamic(() => import('src/modules/shared/modal/components/communityWrite/privacyReminderModal'), { ssr: false });
const PrivacyTips = dynamic(() => import('src/modules/shared/modal/components/privacyTipsModal'), { ssr: false });
const PrivacyTipsImages = dynamic(() => import('src/modules/shared/modal/components/privacyTipsImagesModal'), { ssr: false });
const ProfileSurvey = dynamic(() => import('src/modules/profileSurvey/components/profileSurveyModal'), { ssr: false });
const ProgramCompleted = dynamic(() => import('src/modules/programs/components/completedProgramModal'), { ssr: false });
const ProgramEnroll = dynamic(() => import('src/modules/programs/components/enrollInProgramModal'), { ssr: false });
const Report = dynamic(() => import('src/modules/shared/modal/components/report/reportModal'), { ssr: false });
const SavePost = dynamic(() => import('src/modules/community/singlePost/components/savePostModal'), { ssr: false });
const SearchCommunityFilterOptions = dynamic(() => import('src/modules/search/posts/components/searchCommunityFilterOptionsModal'), { ssr: false });
const SearchSortByOptions = dynamic(() => import('src/modules/community/communitySearch/components/searchSortByOptionsModal'), { ssr: false });
const SentimentFeedback = dynamic(() => import('src/modules/shared/feedback/sentimentFeedbackModal'), { ssr: false });
const SelectCommunityToPost = dynamic(() => import('src/modules/shared/modal/components/selectCommunityToPostModal'), { ssr: false });
const Signup = dynamic(() => import('src/modules/shared/modal/components/signup/signupModal'), { ssr: false });
const StopEditingPoll = dynamic(() => import('src/modules/community/writePoll/stopEditingPollModal'), { ssr: false });
const SupportDetails = dynamic(() => import('src/modules/community/communityAbout/components/supportDetailsModal'), { ssr: false });
const TagFilterOptions = dynamic(() => import('src/modules/tag/tagFilterOptionsModal'), { ssr: false });
const TakeProfileSurvey = dynamic(() => import('src/modules/profileSurvey/components/takeProfileSurveyModal'), { ssr: false });
const Welcome = dynamic(() => import('src/modules/shared/modal/components/signup/welcomeModal'), { ssr: false });

export default function ModalRoot(): JSX.Element {
  const { modalType } = useSelector(({ modal }) => modal);

  switch (modalType) {
    case ModalType.CreatePollConfirmation: return <CreatePollConfirmation />;
    case ModalType.ChooseTopic: return <ChooseTopic />;
    case ModalType.CommunityGuidelines: return <CommunityGuidelines />;
    case ModalType.CommunityInvites: return <CommunityInvites />;
    case ModalType.CommunityRemoveMember: return <CommunityRemoveMember />;
    case ModalType.CommunityTopConditions: return <CommunityTopConditions />;
    case ModalType.Confirm: return <Confirm />;
    case ModalType.ContactAdmins: return <ContactAdmins />;
    case ModalType.ContactDetails: return <ContactDetails />;
    case ModalType.Default: return <Default />;
    case ModalType.DeletePoll: return <DeletePoll />;
    case ModalType.DeletePost: return <DeletePost />;
    case ModalType.EditBadges: return <EditBadges />;
    case ModalType.EmailConfirmation: return <EmailConfirmation />;
    case ModalType.EmailSubscribeAllPosts: return <EmailSubscribeAllPosts />;
    case ModalType.EmailUnsubscribeAllEmails: return <EmailUnsubscribeAllEmails />;
    case ModalType.ExistingLoginSocial: return <ExistingLoginSocial />;
    case ModalType.Feedback: return <Feedback />;
    case ModalType.GoogleAds: return <GoogleAds />;
    case ModalType.GuidelinesAdd: return <GuidelinesAdd />;
    case ModalType.GuidelinesDelete: return <GuidelinesDelete />;
    case ModalType.Iframe: return <Iframe />;
    case ModalType.ImageDescription: return <ImageDescription />;
    case ModalType.Login: return <Login />;
    case ModalType.MarketingOptIn: return <MarketingOptIn />;
    case ModalType.MessageArchive: return <MessageArchive />;
    case ModalType.MessageEditSubject: return <MessageEditSubject />;
    case ModalType.MessageLeave: return <MessageLeave />;
    case ModalType.MessageMute: return <MessageMute />;
    case ModalType.MessageNew: return <MessageNew />;
    case ModalType.MessageParticipants: return <MessageParticipants />;
    case ModalType.NeedsAssessment: return <NeedsAssessment />;
    case ModalType.NHSTagContent: return <NHSTagContent />;
    case ModalType.Onboarding: return <Onboarding />;
    case ModalType.PrivacyReminder: return <PrivacyReminder />;
    case ModalType.PrivacyTips: return <PrivacyTips />;
    case ModalType.PrivacyTipsImages: return <PrivacyTipsImages />;
    case ModalType.ProfileSurvey: return <ProfileSurvey />;
    case ModalType.ProgramCompleted: return <ProgramCompleted />;
    case ModalType.ProgramEnroll: return <ProgramEnroll />;
    case ModalType.Report: return <Report />;
    case ModalType.SavePost: return <SavePost />;
    case ModalType.SearchFilterOptions: return <SearchCommunityFilterOptions />;
    case ModalType.SearchSortBy: return <SearchSortByOptions />;
    case ModalType.SelectCommunityToPost: return <SelectCommunityToPost />;
    case ModalType.SentimentFeedback: return <SentimentFeedback />;
    case ModalType.Signup: return <Signup />;
    case ModalType.StopEditingPoll: return <StopEditingPoll />;
    case ModalType.SupportDetails: return <SupportDetails />;
    case ModalType.TagFilterOptions: return <TagFilterOptions />;
    case ModalType.TakeProfileSurvey: return <TakeProfileSurvey />;
    case ModalType.Welcome: return <Welcome />;
    default: return null;
  }
}
