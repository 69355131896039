import { useRouter } from 'next/router';
import { DropdownItem } from 'reactstrap';
import { setLangCookie } from 'src/utils/cookie';
import { updateLanguage } from 'src/modules/shared/context/actions';
import ButtonDropdown from 'src/components/buttons/buttonDropdown';
import i18n from 'src/utils/translate';
import { useDispatch, useSelector } from 'src/store/store';
import { Lang } from 'src/constants/types';
import { language, langNames } from 'src/constants/language';

export default function LanguageChange(): JSX.Element {
  const dispatch = useDispatch();
  const router = useRouter();

  const { lang = language.EN } = useSelector(({ context }) => context);

  const onChange = async (lang: Lang) => {
    setLangCookie(lang);
    await dispatch(updateLanguage(lang));
    i18n.changeLanguage(lang);
    router.reload();
  };

  return (
    <ButtonDropdown
      color="languages"
      dataTestId="languages-button"
      text={`${i18n.t('Language')}: ${langNames[lang]}`}
    >
      {Object.keys(langNames).map(language => (
        <DropdownItem
          data-testid="language-selector"
          key={language}
          onClick={() => onChange(language as Lang)}
        >
          {langNames[language]}
        </DropdownItem>
      ))}
    </ButtonDropdown>
  );
}
