// @ts-strict-ignore
import { ReactNode } from 'react';
import classNames from 'classnames';

export default function Section({ children, className, withColumns }: { children: ReactNode; className?: string; withColumns?: boolean; }) {
  const contentclassName = classNames({
    'home__section-content': true,
    'home__section-content--split': withColumns,
    [className]: !!className,
  });

  return (
    <section className="home__section">
      <div className={contentclassName}>
        {children}
      </div>
    </section>
  );
}
