// @ts-strict-ignore
import { ReactNode } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

type Props = {
  children: ReactNode;
  show?: boolean;
  timeout?: { enter: number, exit: number };
  transitionType: string;
}
export default function Animate({ show = true, timeout = { enter: 500, exit: 1000 }, children, transitionType }: Props) {

  function ensureChildArray(children) {
    return (Array.isArray(children) ? children : [children]).filter(x => !!x);
  }

  const items = show ? ensureChildArray(children) : [];

  return (
    <TransitionGroup className="animate-component">
      {items.map((item, i) => (<CSSTransition classNames={transitionType} key={i} timeout={timeout}>{item}</CSSTransition>))}
    </TransitionGroup>
  );
}
