import { Fragment } from 'react';
import styled from 'styled-components';
import { Guideline } from 'src/constants/types';

type GuidelinesList = {
  guidelines: Guideline[];
  hideSubtitle?: boolean;
}

export default function GuidelinesList({ guidelines, hideSubtitle }: GuidelinesList) {
  return (
    <List hideSubtitle={hideSubtitle}>
      {guidelines.filter(guideline => guideline.title).map(guideline => (
        <Fragment key={guideline.number}>
          <dt data-number={guideline.number}>
            {guideline.title}
          </dt>
          {!hideSubtitle && <dd>{guideline.subtitle}</dd>}
        </Fragment>
      ))}
    </List>
  );
}

const List = styled.dl<{ hideSubtitle?: boolean }>`
  position: relative;
  margin: 20px 0;
  padding-left: calc(${({ theme }) => theme.fontLineHeightS} + 10px);
  dt {
    margin-bottom: ${({ hideSubtitle }) => hideSubtitle ? '20px' : '10px'};
    font-weight: ${({ hideSubtitle, theme }) => hideSubtitle ? theme.fontWeightNormal : theme.fontWeightBold};
    &::before {
      content: attr(data-number);
      position: absolute;
      display: inline-block;
      background-color: ${({ theme }) => theme.colorGreyLight};
      border-radius: 100%;
      height: ${({ theme }) => theme.fontLineHeightS};
      width: ${({ theme }) => theme.fontLineHeightS};
      text-align: center;
      left: 0;
    }
  }
  dd {
    margin-bottom: 20px;
    color: ${({ theme }) => theme.colorTextLight};
  }
`;
