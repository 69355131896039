import { useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'src/store/store';
import SiteBar from 'src/components/siteBar/siteBar';
import SiteFooter from 'src/components/footer/siteFooter';
import Alert from 'src/components/alert/alert';
import ModalRoot from 'src/components/modals/modalRoot';
import HeadSeo from 'src/utils/head';
import { clearCurrentCommunity } from 'src/modules/shared/context/actions';
import VerificationBar from 'src/components/verificationBar';
import BackToTop from 'src/components/buttons/backToTop';
import { Metadata } from 'src/constants/types';

type Props = {
  children?: ReactNode;
  doClearCommunity?: boolean;
  fullWidth?: boolean;
  seo?: Metadata;
}

export default function DefaultLayout({
  children,
  doClearCommunity = true,
  fullWidth = false,
  seo,
}: Props) {
  const dispatch = useDispatch();

  const communitySlug = useSelector(({ context }) => context.currentCommunity?.slug);

  useEffect(() => {
    if (communitySlug && doClearCommunity) {
      dispatch(clearCurrentCommunity());
    }
  }, []);

  const pageContainerClassNames = classNames({
    'default-page-container': !fullWidth,
    'full-width-page-container': fullWidth,
  });

  return (
    <Container>
      <HeadSeo {...seo} />
      <SiteBar />
      <VerificationBar />
      <Alert />
      <Main className={pageContainerClassNames}>
        {children}
      </Main>
      <BackToTop />
      <SiteFooter />
      <ModalRoot />
    </Container>
  );
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  margin-top: ${({ theme }) => theme.navHeightTop};
  flex: 1;
  @media print {
    margin-top: 0;
  }
`;
