// @ts-strict-ignore
import { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styled from 'styled-components';
import { capitalize } from 'underscore.string';
import { useSelector } from 'src/store/store';
import config from 'config/config';
import i18n from 'src/utils/translate';
import { Section } from 'src/constants/footfall';
import { footfall } from 'src/components/siteBar/sitebarFootfall';
import MobileSearchInput from 'src/components/siteBar/mobileSearchInput';
import { LinkButton } from 'styles-js/buttons';
import { Avatar, ImageContainer, ImageIcon, LinkText, TopSiteBar, StyledLink } from 'src/components/siteBar/sharedStyles';
import Icon from 'src/components/icon';
import { useBranch } from 'src/utils/cookie';

export default function MobileSiteBarTop(): JSX.Element {
  const { asPath } = useRouter();
  const route = asPath.split('/')[1];
  const { username, avatarImageUrl } = useSelector(({ context }) => context.currentUser);
  const isOwnProfile = useSelector(({ pages }) => pages.profile?.profileUser?.isOwnProfile);
  const [isSearching, setIsSearching] = useState(false);

  const isB = useBranch() === 'B';

  return (
    <TopContainer className="js-sitebar">
      <Link
        legacyBehavior
        passHref
        as="/newsfeed"
        href="/home/newsfeed"
      >
        <StyledLink $isActive={'newsfeed' === route} $isB={isB} onClick={() => footfall(Section.HULogo)}>
          <ImageContainer>
            <ImageIcon alt="Home" src={`${config.cdn.logos}H-logo-speech-bubble-green.svg`} />
          </ImageContainer>
          <LinkText $isActive={'newsfeed' === route}>{capitalize(i18n.t('home'))}</LinkText>
        </StyledLink>
      </Link>

      <MobileSearchButton
        aria-label="Start a search"
        onClick={() => setIsSearching(true)}
      >
        {i18n.t('Search HealthUnlocked')}
        <Icon icon="search" />
      </MobileSearchButton>

      <Link
        legacyBehavior
        passHref
        as={`/user/${username}`}
        href={`/user/profile?username=${username}`}
      >
        <StyledLink $isActive={'user' === route && isOwnProfile} $isB={isB} onClick={() => footfall(Section.More)}>
          <ImageContainer>
            <Avatar alt="Your avatar" src={avatarImageUrl} />
          </ImageContainer>
          <LinkText $isActive={'user' === route && isOwnProfile}>{capitalize(i18n.t('profile'))}</LinkText>
        </StyledLink>
      </Link>

      {isSearching && (
        <SearchContainer>
          <MobileSearchInput onCancel={() => setIsSearching(false)} />
        </SearchContainer>
      )}
    </TopContainer>
  );
}

const TopContainer = styled(TopSiteBar)`
  display: flex;
  align-items: center;
`;

const MobileSearchButton = styled(LinkButton)`
  flex-grow: 1;
  min-width: 110px;
  padding: 0 10px;
  margin: 0 5px;
  line-height: 35px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colorBlueBackground};
  color: ${({ theme }) => theme.colorTextBlack};
  font-size: ${({ theme }) => theme.fontSizeXs};
  text-align: left;
  &:hover {
    color: ${({ theme }) => theme.colorBlackHover};
  }
  .icon {
    float: right;
    height: 35px;
    font-size: 17px;
    color: ${({ theme }) => theme.colorTextBlack};
  }
`;

const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1006;
  background-color: ${({ theme }) => theme.colorWhite};
`;
