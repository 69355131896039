import React from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Editor } from 'slate';
import { useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import Modal from 'src/components/modals/modal';
import { text } from 'styles-js/mixins/typography';
import { Community } from 'src/constants/types';
import { getCommunityLogoUrl } from 'src/utils/linkUtils';
import GuidelinesList from 'src/modules/community/communityAbout/components/guidelinesList';
import { getLocalStorageItem, Key, setLocalStorageItem } from 'src/utils/localStorage';
import { MyEditor } from 'src/components/textEditor/customHelpers';

type ModalProps = {
  redirect?: boolean;
  messageEditor?: Editor;
};
export default function GuidelinesModal() {
  const router = useRouter();
  const { currentCommunity, currentUser } = useSelector(({ context }) => context);
  const { redirect, messageEditor } = useSelector(({ modal }) => modal.modalProps) as ModalProps;

  if (!currentCommunity?.guidelines || !currentUser) return null;

  const onClose = () => {
    let dismissedCommunities = [currentCommunity.communityId];
    const guidelinesDismissed = getLocalStorageItem(Key.guidelinesDismissed);
    if (guidelinesDismissed) {
      const communityIds = JSON.parse(guidelinesDismissed);
      if (Array.isArray(communityIds) && !communityIds.includes(currentCommunity.communityId)) {
        dismissedCommunities = [...communityIds, ...dismissedCommunities];
      }
    }
    setLocalStorageItem(Key.guidelinesDismissed, JSON.stringify(dismissedCommunities));
    if (redirect) {
      router.push(`/community/write?communitySlug=${currentCommunity.slug}`, `/${currentCommunity.slug}/posts/write`);
    }
    if (messageEditor) {
      MyEditor.focus(messageEditor);
    }
  };

  return (
    <Modal
      acceptButtonDataTestId="community-guidelines-modal-accept-button"
      acceptButtonText={i18n.t('Got it!')}
      title=""
      onAccept={onClose}
      onClose={onClose}
    >
      <Images>
        <CommunityLogo alt={currentCommunity.name} src={getCommunityLogoUrl(currentCommunity.logoImageHash)} />
        <UserAvatar alt={currentUser.username} src={currentUser.avatarImageUrl} />
      </Images>
      <Text>{i18n.t('We are excited for your contribution, please have a look at our community guidelines:')}</Text>
      <GuidelinesList guidelines={currentCommunity.guidelines} hideSubtitle={true} />
    </Modal>
  );
}


export const showGuidelines = ({ communityId, guidelines }: Community) => {
  if (!guidelines) return false;

  const guidelinesDismissed = getLocalStorageItem(Key.guidelinesDismissed);
  if (guidelinesDismissed) {
    const communityIds = JSON.parse(guidelinesDismissed);
    if (Array.isArray(communityIds) && communityIds.includes(communityId)) {
      return false;
    }
  }
  return true;
};

const Images = styled.div`
  position: relative;
  height: 150px;
  width: 150px;
  margin: auto;
  img {
    position: absolute;
    border-radius: 100%;
    border: 5px solid ${({ theme }) => theme.colorWhite};
    box-shadow: ${({ theme }) => theme.shadowDefault};
  }
`;

const CommunityLogo = styled.img`
  height: 140px;
  width: 140px;
`;

const UserAvatar = styled.img`
  height: 80px;
  width: 80px;
  right: 0;
  bottom: 0;
`;

const Text = styled.p`
  ${text};
  margin: 20px 0;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;
