import Icon from 'src/components/icon';

export function FollowIcon({ isFollowing, isHover }: { isFollowing: boolean; isHover: boolean; }): JSX.Element {
  if (!isFollowing) {
    return <Icon icon="plus" />;
  } else if (isHover) {
    return <Icon icon="close" />;
  } else {
    return <Icon icon="ok" />;
  }
}
