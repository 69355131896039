import i18n from 'src/utils/translate';

export default function Menu() {
  return (
    <svg
      fill="none"
      height="19"
      viewBox="0 0 27 19"
      width="27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{i18n.t('Menu')}</title>
      <rect
        height="3.5"
        rx="1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        width="25.5"
        x=".75"
        y=".75"
      />
      <rect
        height="3.5"
        rx="1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        width="25.5"
        x=".75"
        y="7.75"
      />
      <rect
        height="3.5"
        rx="1.75"
        stroke="currentColor"
        strokeWidth="1.5"
        width="25.5"
        x=".75"
        y="14.75"
      />
    </svg>
  );
}
