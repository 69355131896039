// @ts-strict-ignore
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import i18n from 'src/utils/translate';
import NavBar from 'src/components/navBar';
import { userHasPermission, useIsCommunityMember } from 'src/utils/context';
import { Permission } from 'src/constants/types';
import { useSelector } from 'src/store/store';
import Icon from 'src/components/icon';

type Props = {
  currentPage: string,
};

export default function CommunityHeaderNavBar({ currentPage = '' }: Props): JSX.Element {
  const { currentCommunity, currentUser } = useSelector(({ context }) => context);
  const isCommunityMember = useIsCommunityMember();
  const isAdmin = userHasPermission(currentUser, Permission.ViewAdminMenu);
  const [isMobile, setIsMobile] = useState(true);

  // this is a workaround - we do not send restrictive roles to f/e. restricted = missing 'createPost' and 'rateItem' permission
  const userIsRestricted = !!currentUser
    && isCommunityMember
    && !userHasPermission(currentUser, Permission.CreatePost)
    && !userHasPermission(currentUser, Permission.RateItem);
  // needs to check for length = 0 because if a user is not a member of a community, they do not have 'createPost'&'rateItem' permissions
  // globally-restricted does not have any permissions
  const userIsGloballyRestricted = currentUser?.permissions?.length === 0;
  // we only hide membersTab if user is restricted/globally restricted.
  const hideMembersTab = userIsRestricted || userIsGloballyRestricted;

  useEffect(() => {
    setIsMobile(document.documentElement.clientWidth < 768);
  }, []);

  if (!currentCommunity) return null;

  return (
    <Container data-testid="community-header-navbar" id="js-community-navbar">
      <NavBar isCompact={true}>
        <Link
          as={`/${currentCommunity.slug}`}
          className={currentPage === 'home' ? 'active' : ''}
          href={`/community/communityHome?communitySlug=${currentCommunity.slug}`}
        >
          {i18n.t('Home')}
        </Link>

        {!currentUser && (
          <Link
            as={`/${currentCommunity.slug}/about`}
            className={currentPage === 'about' ? 'active' : ''}
            href={`/community/communityAbout?communitySlug=${currentCommunity.slug}`}
          >
            {i18n.t('About')}
          </Link>
        )}

        <Link
          as={isMobile ? `/${currentCommunity.slug}/posts#mobile` : `/${currentCommunity.slug}/posts`}
          className={currentPage === 'posts' ? 'active' : ''}
          href={`/community/communityPosts?communitySlug=${currentCommunity.slug}`}
        >
          {i18n.t('Posts')}
        </Link>

        {!currentCommunity.isArchived && !hideMembersTab && (
          <Link
            as={`/${currentCommunity.slug}/members`}
            className={currentPage === 'members' ? 'active' : ''}
            href={`/community/communityMembers?communitySlug=${currentCommunity.slug}`}
          >
            {i18n.t('Members')}
          </Link>
        )}

        {currentUser && (
          <Link
            as={`/${currentCommunity.slug}/about`}
            className={currentPage === 'about' ? 'active' : ''}
            href={`/community/communityAbout?communitySlug=${currentCommunity.slug}`}
          >
            {i18n.t('About')}
          </Link>
        )}

        {isAdmin && (
          <a
            className={currentPage === 'settings' ? 'active' : ''}
            href={`/${currentCommunity.slug}/settings/dashboard`}
          >
            <Icon icon="cog" /> {i18n.t('Admin')}
          </a>
        )}
      </NavBar>
    </Container>
  );
}

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
  margin-bottom: 40px;
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 0 10px;
  }
`;
