// @ts-strict-ignore
import styled, { css } from 'styled-components';
import ImageUpload from 'src/components/imageUpload';
import { Role } from 'src/constants/types';
import { useSelector } from 'src/store/store';
import { getCommunityBannerUrl } from 'src/utils/linkUtils';
import i18n from 'src/utils/translate';
import { saveAndCropBannerImage } from 'src/api/community';

export default function CommunityBanner() {
  const { currentCommunity, currentUser } = useSelector(({ context }) => context);

  const imageUrl = getCommunityBannerUrl(currentCommunity?.bannerImageHash);
  const imageUrlMobile = getCommunityBannerUrl(currentCommunity?.bannerImageHash, true);
  const isHUStaff = currentUser?.roles?.includes(Role.HealthUnlocked);

  if (isHUStaff) {
    return (
      <ImageUpload
        buttonText={i18n.t('Add banner')}
        currentImageUrl={imageUrl}
        customCss={banner}
        saveRequest={(formData) => saveAndCropBannerImage(currentCommunity?.slug, formData)}
        type="banner"
      />
    );
  }
  return (
    <Banner imageUrl={imageUrl} imageUrlMobile={imageUrlMobile} />
  );
}

const banner = css`
  width: 100%;
  max-width: 1900px;
  padding-top: 20%;
  margin: 0 auto;
  background-size: cover;
  overflow: hidden;

  @media (min-width: 1900px) {
    padding-top: 0;
    height: 380px;
  }
`;
const Banner = styled.div<{ imageUrl: string, imageUrlMobile: string }>`
  ${banner}
  background-image: url(${({ imageUrlMobile }) => imageUrlMobile});
  @media (${({ theme }) => theme.overScreenMedium}) {
    background-image: url(${({ imageUrl }) => imageUrl});
  }
`;
